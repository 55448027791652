import clsx from 'clsx';
import { ParagraphAndImageProps } from 'interfaces/cms/content';
import ParagraphHeader from 'components/ParagraphHeader';
import Markdown from 'components/common/Markdown';
import BasicLinkComponent from 'components/common/Link';
import ButtonComponent from 'components/common/Button';
import Paragraph from './Paragraph';
import SingleParagraph from './SingleParagraph';
import styles from './ParagraphAndImage.module.scss';

function ParagraphAndImage({
  header,
  background,
  rows,
  priority,
}: Readonly<ParagraphAndImageProps>) {
  const gridLgCols = {
    1: 'lg:grid-cols-1',
    2: 'lg:grid-cols-2',
    3: 'lg:grid-cols-3',
    4: 'lg:grid-cols-4',
    5: 'lg:grid-cols-5',
    6: 'lg:grid-cols-6',
    7: 'lg:grid-cols-7',
    8: 'lg:grid-cols-8',
    9: 'lg:grid-cols-9',
    10: 'lg:grid-cols-10',
    11: 'lg:grid-cols-11',
    12: 'lg:grid-cols-12',
  } as Record<number, string>;

  return (
    <div
      className={clsx(
        'py-[1.875rem] px-[0.9375rem] md:px-[1.25rem]',
        styles.paragraphAndImage
      )}
      style={{ background }}
    >
      {header && (
        <div className="max-w-[69.375rem] my-0 mx-auto pt-0 pb-[1.875rem] px-0">
          <ParagraphHeader {...header} />
        </div>
      )}

      <div className="max-w-[69.375rem] mx-auto">
        {rows?.map((row) => (
          <div key={row.id} className="pt-[1.875rem] first-of-type:pt-0">
            {row.columns === 1 && row.paragraph ? (
              <SingleParagraph
                {...row.paragraph[0]}
                isImageMobileFullWidth={!header}
              />
            ) : (
              <div
                className={clsx(
                  'grid grid-cols-1 gap-x-[1.875rem] gap-y-[0.625rem] justify-center',
                  gridLgCols[row.columns]
                )}
              >
                {row?.paragraph?.map((paragraph) => (
                  <Paragraph
                    key={paragraph.id}
                    {...paragraph}
                    priority={priority}
                  />
                ))}
              </div>
            )}

            {row.remark && row.remark.trim() !== '' && (
              <div
                className={clsx(
                  'mt-[1.875rem] text-[1.875rem]',
                  styles.paragraphAndImage__remark
                )}
              >
                <Markdown
                  textAlign="left"
                  md={row.remark}
                  textColor="#4b4b4b"
                />
              </div>
            )}

            {row.rowButton &&
              (row.rowButton.buttonUrl || row.rowButton.buttonPageSlug) && (
                <div className="text-center mx-auto w-full md:w-[31.25rem] mt-[1.875rem]">
                  <BasicLinkComponent
                    href={
                      row.rowButton.buttonUrl
                        ? row.rowButton.buttonUrl
                        : row.rowButton.buttonPageSlug?.slug
                    }
                    rel={row.rowButton?.buttonLinkRel}
                    isExternalLink={!!row.rowButton.buttonUrl}
                  >
                    <ButtonComponent
                      isRowButton
                      {...row.rowButton}
                      buttonType="primary"
                      buttonSize="normal"
                    />
                  </BasicLinkComponent>

                  {row.rowButton.disclaimer && (
                    <div className="max-w-[23.125rem] my-0 mx-auto">
                      <div className="mt-[0.625rem] flex justify-center">
                        <Markdown
                          textAlign="center"
                          md={row.rowButton.disclaimer.title || ''}
                          textColor={
                            row.rowButton.disclaimer.color || 'text-textColor'
                          }
                          hasLockIcon={row.rowButton.disclaimer.lockIcon}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ParagraphAndImage;
